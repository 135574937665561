<template>
  <v-container>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0 ml-5 mt-5">
      <h1>
        Puntos de partida de
        <span class="font-weight-light">{{
          selectedCorporative.shortName
        }}</span>
      </h1>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row justify-md="end" class="mx-8 mx-md-0">
          <v-btn
            color="primary"
            class="ma-2 white--text"
            @click="edit()"
            :loading="loading"
          >
            <v-icon right dark class="mr-3"> fas fa-plus </v-icon>
            Crear nuevo punto de partida
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-3">
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          :headers="headers"
          :search="search"
          :loading="loading"
          :items="points"
          sort-by="name"
          :items-per-page="5"
        >
          <template v-slot:[`item.location`]="{ item }">
            <a @click="showMap(item)">Ver en mapa</a>
          </template>
          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item,
                  'active'
                )
              "
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.isPrincipal`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { isPrincipal: item.isPrincipal },
                  item['.key'],
                  item,
                  'isPrincipal'
                )
              "
              v-model="item.isPrincipal"
            ></v-switch>
          </template>
          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="edit(item)" small color="primary"> Editar </v-btn>

            <v-btn
              small
              class="ml-3 white--text"
              @click="deleteItem(item)"
              color="red darken-4"
              >Eliminar</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog max-width="700" v-model="editDialog" v-if="editDialog">
      <Edit
        :businessId="selectedCorporative['.key']"
        :selectedItem="selectedItem"
        @cancel="editDialog = false"
        @success="handleSuccess"
      />
    </v-dialog>

    <v-dialog max-width="450" v-model="deleteDialog" v-if="deleteDialog">
      <Delete
        :loading="loading"
        :item="selectedItem"
        @cancel="deleteDialog = false"
        @confirm="confirmDelete"
      />
    </v-dialog>

    <v-dialog max-width="800" v-model="mapDialaog" v-if="mapDialaog">
      <v-card :loading="loading">
        <v-card-title class="headline">
          Ubicación de {{ selectedItem.name }}
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="mapDialaog = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <map-view
            :longitude="selectedItem.address.geopoint.longitude"
            :latitude="selectedItem.address.geopoint.latitude"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapState } from "vuex";
import Edit from "./edit";
import Delete from "@/components/delete";
import mapView from "@/components/map-view";

export default {
  name: "corporative-starting",
  components: {
    Edit,
    Delete,
    mapView,
  },

  data() {
    return {
      points: [],
      snackbar: false,
      mapDialaog: false,
      snackbarText: "",
      loading: true,
      saving: false,
      selectedItem: null,
      editDialog: false,
      deleteDialog: false,
      headers: [
        { value: "name", text: "Nombre" },
        { value: "contactName", text: "Contacto" },
        { value: "contactPhone", text: "Teléfono del contacto" },
        { value: "location", text: "Ubicación" },
        { value: "isPrincipal", text: "Principal" },
        { value: "active", text: "Activo" },
        { value: "options", align: "end" },
      ],
    };
  },
  watch: {
    selectedCorporative() {
      this.points = [];
      this.getBusinessData();
    },
  },
  computed: {
    ...mapState(["selectedCorporative", "search"]),
  },
  methods: {
    confirmDelete() {
      this.loading = true;
      db.collection("businessesCorporative")
        .doc(this.selectedCorporative[".key"])
        .collection("startingPoints")
        .doc(this.selectedItem[".key"])
        .update({
          deleted: true,
          deletedAt: new Date(),
          deletedBy: this.$store.state.user[".key"],
        })
        .then((res) => {
          this.snackbarText = "Punto de partida eliminado.";
          this.snackbar = true;
          this.loading = false;
          this.deleteDialog = false;
        })
        .catch((err) => {
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
          this.loading = false;
        });
    },
    edit(item) {
      this.selectedItem = item;
      this.editDialog = true;
    },
    showMap(item) {
      this.selectedItem = item;
      this.mapDialaog = true;
    },
    deleteItem(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    checkPrincipalPoint(principalId) {
      this.points.forEach((item) => {
        db.collection("businessesCorporative")
          .doc(this.selectedCorporative[".key"])
          .collection("startingPoints")
          .doc(item[".key"])
          .update({
            isPrincipal: principalId == item[".key"] ? true : false,
          });
      });
    },

    switchControlChanged(data, docId, point, field) {
      this.loading = true;

      data.modifiedAt = new Date();
      data.modifiedBy = this.$store.state.user[".key"];

      db.collection("businessesCorporative")
        .doc(this.selectedCorporative[".key"])
        .collection("startingPoints")
        .doc(docId)
        .update(data)
        .then((res) => {
          if (data.isPrincipal) {
            this.checkPrincipalPoint(docId);
          }
          this.loading = false;
          this.snackbarText = "Información actualizada exitosamente.";
          this.snackbar = true;
        });
    },
    handleSuccess(e) {
      if (e) {
        this.checkPrincipalPoint(this.selectedItem[".key"]);
      }

      this.snackbarText = "Punto de partida editado exitosamente.";
      this.snackbar = true;
      this.selectedItem = null;
      this.editDialog = false;
    },
    async getBusinessData() {
      if (!this.selectedCorporative) {
        this.$router.push({ path: "/" });
      }

      await this.$binding(
        "points",
        db
          .collection("businessesCorporative")
          .doc(this.selectedCorporative[".key"])
          .collection("startingPoints")
          .where("deleted", "==", false)
      );
      this.loading = false;
    },
  },

  mounted() {
    this.$store.state.visibleSearch = true;
    this.$store.state.searchTerm = "Punto de Partida";
    this.getBusinessData();
  },
};
</script>

<style lang="scss">
#mapid {
  height: 40vh;
}
.leaflet-marker-icon {
  border-radius: 100%;
  background-color: #f96921;
  background-size: cover;
  animation-name: in;
  animation-duration: 3s;
}
.leaflet-shadow-pane {
  animation-name: in;
  animation-duration: 3s;
}
@keyframes in {
  0% {
    left: -200px;
    top: -100px;
  }
  25% {
    left: 0;
    top: 0;
  }
}
.save-btn {
  margin-top: 20px;
}
.vue2leaflet-map {
  z-index: 1;
}
</style>
